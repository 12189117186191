
import { Component, Inject, Prop, Ref, Vue } from "vue-property-decorator";
import _ from "lodash";
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { DependencyContainer } from "tsyringe";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";

import { EquipmentBrandInfos } from "@/domain";

import { IEquipmentBrandService } from "@/services";

@Component({
  components: {
    UpdateUi,
  },
})
export default class EquipmentBrandUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: EquipmentBrandInfos;

  @Prop({ required: true })
  forbiddenNames!: string[];

  @Ref("form")
  form!: any;

  get equipmentBrandService() {
    return this.container.resolve<IEquipmentBrandService>(
      S.EQUIPMENT_BRAND_SERVICE
    );
  }

  loading: boolean = false;
  name: string | null = null;

  mounted() {
    this.name = this.item.name;
  }

  async update() {
    try {
      this.loading = true;
      const brandUpdated = await this.equipmentBrandService.update(
        this.item.id,
        {
          name: this.name!,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", brandUpdated);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(brandName: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (b) => b.toUpperCase() == brandName.toUpperCase()
      ) != -1 && this.item.name.toUpperCase() != brandName.toUpperCase()
    );
  }
}
