
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class UpdateUI extends Vue {
  @Ref("form")
  form!: any;

  @Prop({ required: true })
  title!: boolean;

  @Prop({ required: true })
  loading!: boolean;

  valid: boolean = true;

  submit() {
    if (this.form.validate()) {
      this.$emit("update");
    }
  }
}
